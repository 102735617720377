.container {
  margin-left: 12rem;
  margin-top: -2rem;
}
.container .createbutton {
  background-color: #0077b6;
  color: #fff;
  cursor: pointer;
}
.section .DialogTitle {
}
