@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  margin-top: 20px;
  padding-top: 55px;
  padding-right: 10px;
  padding-left: 10px;
}

h2 {
  font-family: "Josefin Sans", cursive;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* margin-top: 20px; */
  
}

/* .signup{
  text-decoration: none;
   
} */
/* .form{
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
} */

.wrapper {
  display: flex;
  flex-direction: row;
}

.left {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.right {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

#img-pane {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
  align-items: center;
  
}

@media only screen and (min-width: 1024px) {
  .left {
    flex: 2 1;
  }

  .right {
    flex: 6 1;
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
    /* padding: 3rem; */
    justify-content: center;
  }
  .left{
    flex:1 1
  }
  .right{
    flex:1 1
  }
  .logo{
    max-width: 300px;
  }
}

.container {
  margin-left: 12rem;
  margin-top: -2rem;
}
.container .createbutton {
  background-color: #0077b6;
  color: #fff;
  cursor: pointer;
}
.section .DialogTitle {
}

.container {
  margin-left: 12rem;
  margin-top: -2rem;
}
.container .createbutton {
  background-color: #0077b6;
  color: #fff;
  cursor: pointer;
}
.section .DialogTitle {
}

.container {
  margin-left: 12rem;
  margin-top: -2rem;
}
.container .createbutton {
  background-color: #0077b6;
  color: #fff;
  cursor: pointer;
}
.section .DialogTitle {
}

.drop-file-input {
  position: relative;
  width: auto;
  height: 200px;
  border: 2px dashed var(--border-color);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--input-bg);
}

@media screen and (max-width: 600px) {
  .drop-file-input {
    position: relative;
    width: auto;
    height: 200px;
    border: 2px dashed var(--border-color);
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--input-bg);
  }
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  color: var(--txt-second-color);
  font-weight: 600;
  padding: 10px;
}

.drop-file-input__label img {
  width: 100px;
}

.drop-file-preview {
  margin-top: 30px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: var(--input-bg);
  padding: 15px;
  border-radius: 20px;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drop-file-preview__item__del {
  background-color: var(--box-bg);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

:root {
  --body-bg: #f5f8ff;
  --box-bg: #fff;
  --input-bg: #f5f8ff;
  --txt-color: #2f2d2f;
  --txt-second-color: #ccc;
  --border-color: #4267b2;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  height: 100vh;
} */

.box {
  background-color: var(--box-bg);
  padding: 30px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}

.header {
  margin-bottom: 30px;
  text-align: center;
}

.Loader_Loader__2CHFV,
.Loader_Loader__2CHFV:before,
.Loader_Loader__2CHFV:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: Loader_load7__3LMkZ 1.8s infinite ease-in-out;
}
.Loader_Loader__2CHFV {
  color: #2145e6;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.Loader_Loader__2CHFV:before,
.Loader_Loader__2CHFV:after {
  content: "";
  position: absolute;
  top: 0;
}
.Loader_Loader__2CHFV:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.Loader_Loader__2CHFV:after {
  left: 3.5em;
}
@keyframes Loader_load7__3LMkZ {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

